import React, { useEffect, useState } from 'react'
import { Fade } from "react-awesome-reveal";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import icon from "../assets/ic_door.png"
import iconK from "../assets/ic_kitchen.png"
import iconC from "../assets/ic_carpentry.png"
import { Button, Grid, Typography } from '@mui/material';

const ChatWithUs = (props) => {

    const types = [
        {
            type: 'doors',
            title: "Doors",
            description: "Need help choosing the perfect door or have questions about installation and maintenance? Our expert team is here to assist you. Start chatting with us now!",
            icon: icon,
            persons: [
                // {
                //     id: 1,
                //     name: "AR. AHMAD ABOUSEIDOU",
                //     number: "+965 99402876",
                //     wapNumber: "+96599402876",
                // },
                // {
                //     id: 2,
                //     name: "AR. HASAN ISHAQ",
                //     number: "+965 9402 5136",
                //     wapNumber: "+96594025136",
                // },
                // {
                //     id: 1,
                //     name: "AR. AHMAD ABOUSEIDOU",
                //     number: "+965 99402876",
                //     wapNumber: "+96599402876",
                // },
                // {
                //     id: 2,
                //     name: "AR. HASAN ISHAQ",
                //     number: "+965 9402 5136",
                //     wapNumber: "+96594025136",
                // },
                // {
                //     id: 3,
                //     name: "ENG. FERAS ZAKARIA LIBDEH",
                //     number: "+965 9889 4632",
                //     wapNumber: "+96598894632",
                // },
                {
                    id: 5,
                    name: "MR. MOHAMMED AL ARABI",
                    number: "+965 9721 9046",
                    wapNumber: "+96597219046",
                }
            ]
        },
        {
            type: 'kitchen',
            title: "Kitchen Furniture",
            description: "Looking for the ideal kitchen furniture or need design tips? Our knowledgeable team is ready to help. Start chatting with us now!",
            icon: iconK,
            persons: [
                {
                    id: 3,
                    name: "ENG. FERAS ZAKARIA LIBDEH",
                    number: "+965 9889 4632",
                    wapNumber: "+96598894632",
                },
                {
                    id: 4,
                    name: "ENG. ABU FERAS",
                    number: "+965 9721 7833",
                    wapNumber: "+96597217833",
                },
            ]
        },
        {
            type: 'carpentry',
            title: "Carpentry",
            description: "Have carpentry questions or need advice on your project? Our expert team is here to assist you. Start chatting with us now!",
            icon: iconC,
            persons: [

                {
                    id: 1,
                    name: "AR. AHMAD ABOUSEIDOU",
                    number: "+965 99402876",
                    wapNumber: "+96599402876",
                },
                {
                    id: 2,
                    name: "AR. HASAN ISHAQ",
                    number: "+965 9402 5136",
                    wapNumber: "+96594025136",
                },
            ]
        }
    ]
    const [currentType, setCurrentType] = useState(null)



    useEffect(() => {
        let currentType = types.find(t => t.type == props.contactUsType)

        if (currentType == undefined) {
            currentType = types[0]
        }

        setCurrentType(currentType)
    }, [props.contactUsType])




    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100dvh',
        }}>

            {currentType && <Fade duration={150} direction='right' style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography style={{ fontSize: 30, color: 'white', fontWeight: 'bold', marginBottom: 20 }}>{`Chat about ${currentType.title}`}</Typography>
                    <Typography style={{ fontSize: 15, textAlign: 'center', paddingLeft: "10%", paddingRight: '10%' }}>{`${currentType.description}`}</Typography>
                </div>
            </Fade>}

            {currentType && <Fade duration={300} direction='right' style={{ flex: 1 }}>
                {currentType.persons.map(person =>
                    <Grid container style={{ marginTop: 20, marginBottom: 20 }} spacing={2} key={person.id + ''}>
                        <Grid item xs={1} md={3} />
                        <Grid item xs={10} md={6}>
                            <Button href={`/${person.id}`} variant="outlined"
                                fullWidth
                                style={{ color: 'white', borderColor: 'white', fontSize: 12 }}
                                startIcon={<PersonOutlineOutlinedIcon />}>
                                {person.name}
                            </Button>
                        </Grid>
                        <Grid item xs={1} md={3} />
                        <Grid item xs={1} md={3} />
                        <Grid item xs={10} md={6}>
                            <Button href={`https://wa.me/${person.wapNumber}`} variant="outlined"
                                fullWidth
                                style={{ color: 'white', borderColor: 'white', fontSize: 12 }}
                                startIcon={<WhatsAppIcon />}>
                                {person.number}
                            </Button>
                        </Grid>
                        <Grid item xs={1} md={3} />
                    </Grid>
                )}
            </Fade>}

        </div >
    )
}

export default ChatWithUs