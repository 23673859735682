import React, { } from "react";
import { Fade } from "react-awesome-reveal";
import ComingSoon from "../../assets/comingSoon.gif"
import Door from "../../assets/ic_door.png"
import Kitchen from "../../assets/ic_kitchen.png"
import Carpentry from "../../assets/ic_carpentry.png"
import "./styles.css";
import { Button } from "@mui/material";



function WorkInProgress(props) {

    const bottomMenu = [
        {
            id: '1',
            label: 'Doors',
            link: "/comingsoon",
            icon: Door
        },
        {
            id: '2',
            label: 'Kitchen',
            link: "/comingsoon",
            icon: Kitchen
        },
        {
            id: '3',
            label: 'Carpentry',
            link: "/comingsoon",
            icon: Carpentry
        },
    ]


    return (
        <section id="about">
            <Fade duration={1000}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100dvh',
                }} >
                    <div style={{ height: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <img src={ComingSoon} />
                    </div>

                    {/* <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around'
                    }}>

                        {bottomMenu.map((item) => <Button
                            href="/comingsoon"
                            key={item.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <img src={item.icon} style={{ height: 50, width: 50 }} />
                            <p style={{ fontSize: 15, color: 'white', font: " 'opensans-bold', sans-serif", marginBottom: 30, marginTop: 10 }}>{item.label}</p>
                        </Button>)}

                    </div> */}

                </div>
            </Fade>
        </section>
    );
}

export default WorkInProgress;