import React, { useEffect, useRef, useState } from "react";
import { Fade, Reveal } from "react-awesome-reveal";
import white_logo from "../assets/logo_white.png"
import { keyframes } from "@emotion/react";
import WorkInProgress from "./WorkInProgress/WorkInProgress";

export default function Header(props) {
  const buttonRef = useRef(null);
  const [showCatalogue, setShowCatalogue] = useState(false)


  useEffect(() => {

    let tracker = null;

    tracker = setTimeout(() => {
      // buttonRef.current.click();
      setShowCatalogue(true)
    }, 3000);


    return () => {
      if (tracker) {
        clearTimeout(tracker)
      }
    }

  }, [])

  const customAnimation = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, -${window.innerHeight}px, 0);
  }
`;


  return (
    <div id="home" style={{ height: window.innerHeight }} >
      {showCatalogue ?
        <WorkInProgress /> :
        <Reveal keyframes={customAnimation}
          delay={1800}
          // fraction={1}
          duration={1200}
          // direction='up'
          style={{
            height: "100%", width: "100%",
          }}>

          <div style={{
            height: "100%", width: "100%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>

            <div style={{ position: 'absolute', height: "100%", width: "100%", background: 'rgba(0,0,0,0.85)' }} />

            <Fade duration={600} cascade direction='up' damping={0.9}>
              <img style={{ height: 200, width: 200 }} src={white_logo} alt='Logo' />

              <h2 style={{ fontSize: 35, color: 'white', font: " 'opensans-bold', sans-serif", marginBottom: 30 }}>Al-Qattan Home</h2>
            </Fade>

          </div>

        </Reveal>}

    </div>
  );

}
