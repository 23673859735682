import React, { useState } from 'react';
import './LoginForm.css'; // Import the CSS file for styling
import Logo from "../../assets/logo_white.png"
import { Fade } from "react-awesome-reveal";
import { Button } from '@mui/material';

function LoginForm({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (username == 'alqattan' && password == 'admin') {
            onLogin()
        }
    };

    return (
        <div className="login-container">
            <Fade duration={400} direction='up'>
                <div className="logo">
                    {/* Replace with your logo image or text */}
                    <img src={Logo} alt="Logo" />
                </div>
            </Fade>
            <form onSubmit={handleSubmit} className="login-form">
                <Fade duration={400} delay={100} direction='up'>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                </Fade>
                <Fade duration={400} delay={200} direction='up'>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                </Fade>
                <Fade duration={400} delay={300} direction='up' style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginLeft: 30,
                    marginRight: 30,
                }}>
                    <Button variant="outlined"
                        type="submit"

                        fullWidth
                        style={{
                            color: 'white', borderColor: 'white', fontSize: 18, marginBottom: 55,
                            marginTop: 20
                        }}
                    >
                        Login
                    </Button>
                </Fade>
            </form>
        </div >
    );
}

export default LoginForm;
