import React, { useEffect, useState } from 'react'
import Home from '../Components/Home'
import $ from 'jquery';
import { useParams } from 'react-router-dom'
export default function () {

    const [state, setState] = useState()
    let { showCatalogue } = useParams();

    const getResumeData = () => {
        $.ajax({
            url: "./resumeData.json",
            dataType: "json",
            cache: false,
            success: function (data) {
                setState({ resumeData: data });
            }.bind(this),
            error: function (xhr, status, err) {
                console.log(err);
                alert(err);
            }
        });
    }


    useEffect(() => {
        getResumeData();
    }, [])

    if (!state) {
        return <div></div>
    }

    return (
        <div>
            <Home data={state.resumeData.main} showCatalogue={showCatalogue} />
        </div>
    )
}
