import React, { useRef, useState } from 'react';
import { storage, ref, uploadBytesResumable, getDownloadURL } from '../firebase';
import { Button, Stack, Typography } from '@mui/material';
import CircularProgressWithLabel from '../Components/CircularProgressWithLabel';
import LoginForm from '../Components/Login';
import Door from "../assets/ic_door.png"
import Kitchen from "../assets/ic_kitchen.png"
import Carpentry from "../assets/ic_carpentry.png"
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DescriptionIcon from '@mui/icons-material/Description';

const PDFUpload = () => {
    const [files, setFiles] = useState([null, null, null]);
    const [progresses, setProgresses] = useState([0, 0, 0]);
    const [urls, setUrls] = useState(['', '', '']);
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const filesMetaData = [
        {
            name: 'Doors',
            filename: 'doors.pdf',
            icon: Door
        },
        {
            name: 'Kitchen',
            filename: 'kitchen.pdf',
            icon: Kitchen
        },
        {
            name: 'Carpentry',
            filename: 'carpentry.pdf',
            icon: Carpentry
        }
    ]

    const fileInputRefs = [useRef(null), useRef(null), useRef(null)]

    const filenames = ['doors.pdf', 'kitchen.pdf', 'carpentry.pdf'];

    const handleChange = (e, index) => {
        const newFiles = [...files];
        newFiles[index] = e.target.files[0];
        setFiles(newFiles);
    };

    const handleUpload = (index) => {
        if (!files[index]) return;

        const fileRef = ref(storage, `files/${filenames[index]}`);
        const uploadTask = uploadBytesResumable(fileRef, files[index]);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                const newProgresses = [...progresses];
                newProgresses[index] = progress;
                setProgresses(newProgresses);
            },
            (error) => {
                console.error(error);
            },
            () => {
                removePdf(index)
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    const newUrls = [...urls];
                    newUrls[index] = downloadURL;
                    setUrls(newUrls);
                });
            }
        );
    };

    const handleClick = (index) => {
        if (fileInputRefs[index] && fileInputRefs[index].current) {
            fileInputRefs[index].current.click();
        }
    }

    const removePdf = (index) => {
        const tempPDFs = [...files]
        tempPDFs[index] = null
        setFiles(tempPDFs)
    }

    if (!isLoggedIn) {
        return <LoginForm onLogin={() => { setIsLoggedIn(true) }} />
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100dvh' }}>
            {files.map((file, index) => (
                <div key={index} style={{
                    flex: 1, borderBottomWidth: 1,
                    borderStyle: 'dotted',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Stack direction={'row'} style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 10
                    }}>
                        <img src={filesMetaData[index].icon} style={{
                            height: 30, width: 30, marginRight: 10
                        }} />
                        <Typography style={{
                            color: 'white',
                            fontSize: 24,
                            textAlign: 'center'
                        }}>{`${filesMetaData[index].name} Catalouge`}</Typography>
                    </Stack>

                    <div style={{
                        flex: 1, display: 'flex',
                        justifyContent: 'center', alignItems: 'center'
                    }}>

                        <input type="file" accept=".pdf" onChange={(e) => handleChange(e, index)} style={{ display: 'none' }}
                            ref={fileInputRefs[index]} />
                        {(progresses[index] !== 100 && progresses[index] !== 0) ? <CircularProgressWithLabel value={progresses[index]} /> :
                            files[index] == null ? <Button variant="contained" color="primary" component="span" startIcon={<DriveFolderUploadIcon />} onClick={() => handleClick(index)}>
                                Upload PDF
                            </Button> : <Stack
                                direction={'column'} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <DescriptionIcon style={{
                                    color: "white",
                                    width: 50,
                                    height: 50, marginBottom: 10
                                }} />
                                <Button variant="contained" color="error" component="span" onClick={() => removePdf(index)}>Remove Pdf</Button>
                            </Stack>}
                    </div>

                    <button onClick={() => handleUpload(index)} disabled={(progresses[index] !== 100 && progresses[index] !== 0)} style={{ marginBottom: 0 }}>Upload {filesMetaData[index].name} PDF</button>
                </div>
            ))}
        </div>
    );
};

export default PDFUpload;
