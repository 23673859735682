// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyACcgcbJtBbSRNiFCkfUvu18vVMCMVyOwo",
    authDomain: "al-qattan-home.firebaseapp.com",
    projectId: "al-qattan-home",
    storageBucket: "al-qattan-home.appspot.com",
    messagingSenderId: "116633898492",
    appId: "1:116633898492:web:6db569f059f3cadbb83679",
    measurementId: "G-JNSSQNGDY0"
};

const firebaseApp = initializeApp(firebaseConfig);

// const firebaseApp = getApp();
console.log('firebaseApp', firebaseApp);
const storage = getStorage(firebaseApp);
console.log('storage', storage);

export { storage, ref, uploadBytesResumable, getDownloadURL };
